<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div v-if="account_type=='admin'">
      <b-alert :show="showAlerts" dismissible @dismissed="showAlerts=false" variant="warning" class="custom-alert text-orange fw-medium animate__animated animate__shakeX"
        > <i class="uil uil-exclamation-circle font-size-18"></i> All payment gateways <span v-if="inactivePgList.length">
        (<span v-for="(value,index) in inactivePgList" :key="index">{{ value.payment_gateway_name }}<span v-if="inactivePgList.length!==index+1">, </span></span>)
        </span> are currently inactive. Please  <router-link :to="{name:'payment gateway'}" class="fw-medium text-orange text-decoration-underline">check.</router-link> </b-alert
      >
    </div>
    <!-- each of currency volume chart -->
    <div class="row mb-3">
      <div v-for="currencyData in currenciesData" :key="currencyData.currency" class="col-12 col-md-6 col-lg-4 col-xl-3">
        <div class="card p-2 py-3">
          <apexchart type="bar" height="260" :options="getChartOptions(currencyData.currency)" :series="getSeriesData(currencyData)"></apexchart>
          <div class="text-center position-relative" style="top:-10px">{{currencyData.currency}} Balance: {{currencyData.currency}} {{convertCurrencyFormat(currencyData.balance || 0, false)}}</div>
        </div>
      </div>
    </div>
    <div class="row align-items-end mb-3 mb-lg-2">
      <div class="col">
        <h5 class="font-size-15 mb-3 mb-lg-2">Current Overview  ({{ selectedCountry.value }})</h5>
        <select
          class="form-select form-control-lg border-0 shadow-sm pe-5 w-auto"
          v-model="selectedCountry"
          @change="updateCurrency()"
        >
          <option
            v-for="(value, index) in countryList"
            :key="index"
            :value="value"
          >
            {{ value.value }}
          </option>
        </select>
      </div>
      <div class="col-md-auto ms-auto mb-0">
        <div class="form-check form-switch form-check-custom mb-0 me-lg-1 d-inline-block">
          <input class="form-check-input mb-0" type="checkbox" id="liveUpdate" v-model="liveUpdate" @change="liveUpdates">
          <label class="form-check-label fw-normal mb-0" for="liveUpdate">Live Updates</label>
        </div>
      </div>
    </div>
    <div v-if="loading && !liveUpdateLoading">
      <div class="text-center">
        <div
          class="spinner-border text-primary my-3"
          v-if="loading"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    
    <div
      v-if="
        account_type == 'admin' ||
        account_type == 'merchant' ||
        account_type == 'reseller'
      "
    >
      <!-- <div class="row">
        <div
          class="col-12 col-md-6 col-lg-6 col-xl"
          v-for="(item, index) in topStatistics"
          :key="index"
        >
          <div class="card overflow-hidden">
            <div class="card-body border-bottom py-2">
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading">
                    <h4 class="mb-1 mt-1 font-size-22">-</h4> 
                  </div>
                  <h4 class="mb-1 mt-1 font-size-22" v-else>
                    {{ convertThousandMillion(item.data)}}
                  </h4>
                  <p class="text-muted mb-0 font-size-13">{{ item.label }}</p>
                  <p class="text-muted mb-0 font-size-13" v-if="item.data3!==''">Transactions Today: <span class="badge rounded-pill mb-2 font-size-13 lh-0" :class="{'bg-soft-primary':index==0,  'bg-soft-info':index==1,  'bg-soft-warning text-orange':index==2, 'bg-soft-success':index==3 }">{{convertCurrencyFormat(item.data3 || 0, false)}}</span></p>
                </div>
                <div>
                  <div v-if="index == 0">
                    <i
                      class="mdi mdi-chart-line img-fluid animate__animated animate__fadeInRight display-6 text-primary"
                    ></i>
                  </div>
                  <div v-else-if="index == 1">
                    <i
                      class="mdi mdi-chart-bell-curve img-fluid animate__animated animate__fadeInRight display-6 text-info"
                    ></i>
                  </div>
                  <div v-else-if="index == 2">
                    <i
                      class="mdi mdi-chart-arc img-fluid animate__animated animate__fadeInRight display-6 text-warning"
                    ></i>
                  </div>
                  <div v-else-if="index == 3">
                    <i
                      class="mdi mdi-chart-bell-curve-cumulative img-fluid animate__animated animate__fadeInRight display-6 text-success"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body h-100 py-2">
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading">
                    <h4 class="mb-1 mt-1 font-size-22">-</h4>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-22" v-else>
                    {{ convertThousandMillion(item.data2) }}
                  </h4>
                  <p class="text-muted mb-0 font-size-13">{{ item.label2 }}</p>
                  <p class="text-muted mb-0 font-size-13">Transactions This Month: <br><span class="mt-1 badge rounded-pill mb-2 font-size-13 lh-0"
                  :class="{'bg-soft-primary':index==0,  'bg-soft-info':index==1,  'bg-soft-warning text-orange':index==2, 'bg-soft-success':index==3 }"
                  >{{convertCurrencyFormat(item.data4 || 0, false)}}</span></p>
                  
               
                </div>
                <div>
                  <div v-if="index == 0">
                    <i
                      class="mdi mdi-chart-line img-fluid animate__animated animate__fadeInRight display-6 text-primary"
                    ></i>
                  </div>
                  <div v-else-if="index == 1">
                    <i
                      class="mdi mdi-chart-bell-curve img-fluid animate__animated animate__fadeInRight display-6 text-info"
                    ></i>
                  </div>
                  <div v-else-if="index == 2" class="text-end">
                    <div v-if="loading">
                      <h4 class="mb-1 mt-1 font-size-14 fw-normal">-</h4>
                      <p class="text-muted mb-0 font-size-13">Total Outstanding</p>
                    </div>
                    <div v-else>
                      <h4 class="mb-1 mt-1 font-size-22" v-if="!loading">{{ convertThousandMillion(item.data5) }}</h4>
                      <p class="text-muted mb-0 font-size-13" v-if="item.data5!==''">Total Outstanding</p>
                    </div>
                  </div>
                  <div v-else-if="index == 3">
                    <i
                      class="mdi mdi-chart-bell-curve-cumulative img-fluid animate__animated animate__fadeInRight display-6 text-success"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-3" v-if="account_type=='admin'">
          <div class="card overflow-hidden">
            <div class="card-body border-bottom py-3">
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                <i class="uil uil-bell font-size-15 d-inline-block animate__animated animate__swing text-orange"></i> 
                <router-link :to="{name:'processing payin'}" class="text-body" v-if="account_type='admin'"> Processing Transactions</router-link>
                </div>
                <div>
                  <router-link :to="{name:'processing payin'}" class="font-size-16 text-orange fw-medium" v-if="account_type='admin'">{{pending_list.payin || 0}}</router-link>
                  <span v-else class="font-size-16 text-orange fw-medium">{{pending_list.payin || 0}}</span>
                </div>
              </div>
            </div>
            <div class="card-body border-bottom py-3">
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                <i class="uil uil-bell font-size-15 d-inline-block animate__animated animate__swing text-orange"></i> 
                 <router-link :to="{name:'pending payouts'}" class="text-body" v-if="account_type='admin'"> Pending Payout </router-link>
                </div>
                <div>
                  <router-link :to="{name:'pending payouts'}" class="font-size-16 text-orange fw-medium" v-if="account_type='admin'">{{pending_list.payout || 0}}</router-link>
                  <span v-else class="font-size-16 text-orange fw-medium">{{pending_list.payout || 0}}</span>
                </div>
              </div>
            </div>
            <div class="card-body border-bottom py-3">
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <i class="uil uil-bell font-size-15 d-inline-block animate__animated animate__swing text-orange"></i> 
                   <router-link :to="{name:'pending settlements'}" class="text-body" v-if="account_type='admin'">  Pending Settlement </router-link>
                </div>
                <div>
                  <router-link :to="{name:'pending settlements'}" class="font-size-16 text-orange fw-medium" v-if="account_type='admin'">{{pending_list.settlement || 0}}</router-link>
                  <span v-else class="font-size-16 text-orange fw-medium">{{pending_list.settlement || 0}}</span>
                </div>
              </div>
            </div>
            <div class="card-body h-100 py-3">
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <i class="uil uil-bell font-size-15 d-inline-block animate__animated animate__swing text-orange"></i> 
                   <router-link :to="{name:'pending withdrawal'}" class="text-body" v-if="account_type='admin'"> Pending Withdrawal</router-link>
                </div>
                <div>
                  <router-link :to="{name:'pending withdrawal'}" class="font-size-16 text-orange fw-medium" v-if="account_type='admin'">{{pending_list.withdrawal || 0}}</router-link>
                  <span v-else class="font-size-16 text-orange fw-medium">{{pending_list.withdrawal || 0}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="mb-4 mt-2" v-if="account_type=='admin'">
      <div class="row" v-if="account_type=='admin'">
        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
          <div class="card overflow-hidden">
            <div class="card-body border-bottom py-2">
              <h6 class="mt-1 text-primary animate__animated animate__fadeInLeft">Total New Reseller </h6>
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading2">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading2"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else>
                    
                      <span data-plugin="counterup">
                          <countTo :startVal="0" :endVal="parseFloat(admin_overview.new_reseller_today)" :duration="2000"></countTo>
                      </span>
                  </h4>
                  <p class="text-muted mb-0 font-size-13">Today</p>
                 
                </div>
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading2">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading2"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else>
                    <span data-plugin="counterup">
                        <countTo :startVal="0" :endVal="parseFloat(admin_overview.new_reseller_this_months)" :duration="2000"></countTo>
                    </span>
                  </h4>
                  <p class="text-muted mb-0 font-size-13">This Month</p>
                 
                </div>
                <div class="animate__animated animate__fadeInLeft">
                  <i class="mdi mdi-account-multiple-plus-outline img-fluid animate__animated animate__fadeInRight display-6 text-primary"
                    ></i>
                </div>
                </div>
              </div>
              <div class="card-body border-bottom py-2">
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading2">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading2"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else >
                    <span data-plugin="counterup">
                        <countTo :startVal="0" :endVal="parseFloat(admin_overview.total_resellers)" :duration="2000"></countTo>
                    </span>
                  </h4>
                  <p class="text-muted mb-0 font-size-13">Total Resellers</p>
                 
                </div>
                </div>
              </div>
            </div>
            
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
          <div class="card overflow-hidden">
            <div class="card-body border-bottom py-2">
              <h6 class="mt-1 text-info animate__animated animate__fadeInLeft">Total New Merchant </h6>
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading2">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading2"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else>
                    <span data-plugin="counterup">
                        <countTo :startVal="0" :endVal="parseFloat(admin_overview.new_merchant_today)" :duration="2000"></countTo>
                    </span>
                  </h4>
                  <p class="text-muted mb-0 font-size-13">Today</p>
                 
                </div>
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading2">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading2"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else>
                    <span data-plugin="counterup">
                        <countTo :startVal="0" :endVal="parseFloat(admin_overview.new_merchant_this_months)" :duration="2000"></countTo>
                    </span>
                  </h4>
                  <p class="text-muted mb-0 font-size-13">This Month</p>
                 
                </div>
                <div class="animate__animated animate__fadeInLeft">
                  <i
                      class="mdi mdi-account-multiple-plus-outline img-fluid animate__animated animate__fadeInRight display-6 text-info"
                    ></i>
                </div>
                </div>
              </div>
              <div class="card-body border-bottom py-2">
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading2">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading2"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else>
                    <span data-plugin="counterup">
                        <countTo :startVal="0" :endVal="parseFloat(admin_overview.total_merchants)" :duration="2000"></countTo>
                    </span>
                  </h4>
                  <p class="text-muted mb-0 font-size-13">Total Merchants</p>
                 
                </div>
                </div>
              </div>
            </div>
            
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
          <div class="card overflow-hidden">
            <div class="card-body border-bottom py-2">
              <h6 class="mt-1 text-warning animate__animated animate__fadeInLeft">Pending Transactions ({{ selectedCountry.value }})</h6>
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading2">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading2"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else>
                    <span data-plugin="counterup">
                        <countTo :startVal="0" :endVal="parseFloat(admin_overview.pending_transactions)" :duration="2000"></countTo>
                    </span>
                  </h4>
                  <p class="text-muted mb-0 font-size-13">Total Pending Transactions</p>
                 
                </div>
                <div class="animate__animated animate__fadeInLeft">
                  <i
                      class="mdi mdi-timer-outline img-fluid animate__animated animate__fadeInRight display-6 text-warning"
                    ></i>
                </div>
                </div>
              </div>
              <div class="card-body border-bottom py-2">
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading2">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading2"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else>
                  {{admin_overview.longest_period_transaction || '0:00'}}
                  </h4>
                  <p class="text-muted mb-0 font-size-13">Longest Periods</p>
                 
                </div>
                </div>
              </div>
            </div>
            
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
          <div class="card overflow-hidden">
            <div class="card-body border-bottom py-2">
              <h6 class="mt-1 text-success animate__animated animate__fadeInLeft">Success Rate ({{ selectedCountry.value }})</h6>
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading2">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading2"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else>
                    <span data-plugin="counterup">
                        <countTo :startVal="0" :endVal="parseFloat(admin_overview.success_transactions)" :duration="2000"></countTo>
                    </span>
                  </h4>
                  <p class="text-muted mb-0 font-size-13">Total Success Transactions</p>
                 
                </div>
                <div class=" text-nowrap animate__animated animate__fadeInLeft">
                  <div v-if="loading2">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading2"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18 ms-lg-2" v-else>
                    <span data-plugin="counterup">
                        <countTo :startVal="0" :endVal="parseFloat(admin_overview.success_rate || 0)" :duration="2000"></countTo>
                    </span>%
                  </h4>
                  <p class="text-muted mb-0 font-size-13 ms-lg-2">Success Rate</p>
                 
                </div>
                <div class="animate__animated animate__fadeInLeft">
                  <i
                      class="ms-lg-2 mdi mdi-check-circle-outline img-fluid animate__animated animate__fadeInRight display-6 text-success"
                    ></i>
                </div>
                </div>
              </div>
              <div class="card-body border-bottom py-2">
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading2">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading2"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else>
                    <span data-plugin="counterup">
                        <countTo :startVal="0" :endVal="parseFloat(admin_overview.total_transactions)" :duration="2000"></countTo>
                    </span>
                  </h4>
                  <p class="text-muted mb-0 font-size-13">Total Transactions</p>
                 
                </div>
                </div>
              </div>
            </div>
            
        </div>
      </div> -->

      <!-- end row-->
      <div class="row mb-3" v-if="account_type=='admin'">
        <div class="mb-3 col-12 col-lg-7">
          <div class="card">
            <div class="card-title px-3 py-2 border-bottom">
              <div class="d-flex align-items-center mb-0 mb-md-0">
                <div class="me-2 mt-1">
                  <i
                    class="mdi mdi-check-circle-outline text-success font-size-24 lh-1"
                  ></i>
                </div>
                <div>
                  <h4 class="fw-medium font-size-18 text-dark mb-0 p-0">
                    Success Rate
                  </h4>
                </div>
              </div>
            </div>

            <div class="card-body py-2 px-1 px-lg-3">
              <div class="text-center my-5"
                  v-if="loading && !liveUpdateLoading">
                <div
                  class="spinner-border text-muted my-5"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="mt-1" v-else-if="loading" style="min-height: 440px">
              </div>
              <div class="mt-1" v-else-if="!loading" style="min-height: 440px; overflow-x:auto; overflow-y:hidden">
                <apexchart
                  type="bar"
                  style="min-width:420px"
                  class="apex-charts mb-3"
                  dir="ltr"
                  height="450"
                  :options="successRateChart.chartOptions"
                  :series="successRateChart.series"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-5">
          <div class="card">
            <div class="card-title px-3 py-2 border-bottom">
              <div class="d-flex align-items-center mb-2 mb-md-0">
                <div class="me-2">
                  <i
                    class="uil uil-bell font-size-24 text-orange"
                  ></i>
                </div>
                <div>
                  <h4 class="fw-medium font-size-18 text-dark mb-0">
                    Pending Transactions
                  </h4>
                </div>
              </div>
            </div>

            <div class="card-body py-2 px-1 px-lg-2">
              <div class="text-center my-5"
                  v-if="loading && !liveUpdateLoading">
                <div
                  class="spinner-border text-muted my-5"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="mt-1" v-else-if="loading" style="min-height: 430px">

              <div class="table-responsive">
                <apexchart
                  style="min-width: 400px;"
                  type="bar"
                  dir="ltr"
                  height="432"
                  :options="pendingTransactionChart.chartOptions"
                  :series="pendingTransactionChart.series"
                ></apexchart>
                </div>
              </div>
              <div class="mt-1" v-else-if="!loading" style="min-height: 430px">
                <div class="table-responsive">
                <apexchart
                  style="min-width: 400px;"
                  type="bar"
                  dir="ltr"
                  height="432"
                  :options="pendingTransactionChart.chartOptions"
                  :series="pendingTransactionChart.series"
                ></apexchart>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="mb-3" :class="{ 'col-12 col-lg-7': account_type === 'admin', 'col-12 col-lg-12': account_type !== 'admin' }">
          <div class="card">
            <div class="card-title px-3 py-2 border-bottom">
              <div class="d-flex align-items-center mb-0 mb-md-0">
                <div class="me-2 mt-1">
                  <i
                    class="mdi mdi-chart-areaspline text-primary font-size-24 lh-1"
                  ></i>
                </div>
                <div>
                  <h4 class="fw-medium font-size-18 text-dark mb-0 p-0">
                    Statistical Overview
                  </h4>
                </div>
                <div class="ms-auto">
                  <div
                    class="btn-group my-1"
                    role="group"
                    aria-label="Basic example"
                  >
                    <input
                      type="radio"
                      class="btn-check btn-check-info mb-0"
                      v-model="selectedStatisticsType"
                      name="options"
                      id="1"
                      autocomplete="off"
                      value="1D"
                      :disabled="loading"
                      @change="updateStatistics()"
                    />
                    <label class="btn btn-sm btn-outline-info mb-0" for="1"
                      >1D</label
                    >
                    <input
                      type="radio"
                      class="btn-check mb-0"
                      v-model="selectedStatisticsType"
                      name="options"
                      id="2"
                      autocomplete="off"
                      value="7D"
                      :disabled="loading"
                      @change="updateStatistics()"
                    />
                    <label class="btn btn-sm btn-outline-info mb-0" for="2"
                      >7D</label
                    >
                    <input
                      type="radio"
                      class="btn-check mb-0"
                      v-model="selectedStatisticsType"
                      name="options"
                      id="3"
                      autocomplete="off"
                      value="1M"
                      :disabled="loading"
                      @change="updateStatistics()"
                    />
                    <label class="btn btn-sm btn-outline-info mb-0" for="3"
                      >1M</label
                    >
                    <input
                      type="radio"
                      class="btn-check mb-0"
                      v-model="selectedStatisticsType"
                      name="options"
                      id="4"
                      autocomplete="off"
                      value="1Y"
                      :disabled="loading"
                      @change="updateStatistics()"
                    />
                    <label class="btn btn-sm btn-outline-info mb-0" for="4"
                      >1Y</label
                    >
                  </div>
                </div>
              </div>
              
              
            </div>

            <div class="card-body py-2 px-1 px-lg-3">
              <div class="ms-2">
                <div class="d-flex align-items-center">
                  <div
                    v-for="(value, index) in StatisticsTotal"
                    :key="index"
                    class="me-3"
                  >
                    <div class="fw-normal text-muted font-size-14 d-inline-block">
                      {{ value.label }}: 
                    </div>
                    <strong class="fw-medium ms-1" v-if="!loading">{{
                      convertThousandMillion(value.amount)
                    }}</strong>
                    <strong class="fw-medium" v-else>-</strong>
                  </div>
                 
                </div>
              </div>
              <div class="text-center my-5"
                  v-if="loading && !liveUpdateLoading">
                <div
                  class="spinner-border text-muted my-5"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="mt-1" v-else-if="loading" style="min-height: 450px">
              </div>
              <div class="mt-1" v-else-if="!loading" style="min-height: 450px; overflow-x:auto; overflow-y:hidden">
                <apexchart
                  type="line"
                  style="min-width:760px"
                  class="apex-charts"
                  dir="ltr"
                  height="450"
                  :options="depositData.chartOptions"
                  :series="depositData.series"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-5" v-if="account_type=='admin'">
          <div class="card">
            <div class="card-title px-3 py-2 border-bottom">
              <div class="d-flex align-items-center mb-2 mb-md-0">
                <div class="me-2">
                  <i
                    class="uil uil-credit-card font-size-24 text-primary"
                  ></i>
                </div>
                <div>
                  <h4 class="fw-medium font-size-18 text-dark mb-0">
                    Payment Gateway Balance
                  </h4>
                </div>
              </div>
            </div>

            <div class="card-body py-2 px-1 px-lg-2">
              <div class="text-center my-5"
                  v-if="loading && !liveUpdateLoading">
                <div
                  class="spinner-border text-muted my-5"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="mt-1" v-else-if="loading" style="min-height: 470px">

              <div class="table-responsive">
                <apexchart
                  style="min-width: 400px;"
                  type="bar"
                  dir="ltr"
                  height="475"
                  :options="pgBalanceChart.chartOptions"
                  :series="pgBalanceChart.series"
                ></apexchart>
                </div>
              </div>
              <div class="mt-1" v-else-if="!loading" style="min-height: 470px">
                <div class="table-responsive">
                <apexchart
                  style="min-width: 400px;"
                  type="bar"
                  dir="ltr"
                  height="475"
                  :options="pgBalanceChart.chartOptions"
                  :series="pgBalanceChart.series"
                ></apexchart>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="row mb-4" v-if="account_type=='admin'">
        <div class="col-12 col-lg-6 col-xl-4">
          <div class="mb-3">
            <div class="media align-items-center d-flex mb-2">
              <div class="avatar-xs me-2">
                <span class="avatar-title bg-primary rounded-circle text-white"><i class="uil uil-wallet"></i></span></div>
                <div class="media-body"><h5 class="font-size-16 mb-0 text-dark"><small class="font-size-16 d-block">Top 10</small>Balance Resellers</h5>
                </div>
             </div>
            <ul class="list-group rounded shadow-sm" v-if="loading && !liveUpdateLoading">
              <li class="list-group-item pb-2 placeholder-glow" v-for="(value,index) in [0,1,2]" :key="index">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-primary font-size-20"><i class="uil uil-user"></i></span></div>
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                        <span class="placeholder col-8 col-lg-4 rounded"></span></span
                    >
                      <strong class="fw-medium font-size-16 d-block"
                      >
                      <span class="placeholder rounded col-lg-6"></span>
                      </strong
                    >
                    </div>
                </div>
              </li>
            </ul>
            <ul class="list-group rounded shadow-sm">
              <li class="list-group-item pb-2 " v-for="(value,index) in top_resellers_balance" :key="index">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-primary font-size-20"><i class="uil uil-user"></i></span></div>
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                     {{value.reseller_name}}</span
                    >
                      <strong class="fw-medium text-dark font-size-16 d-block"
                      >{{ currencySymbol }}
                      {{
                        convertCurrencyFormat(value.amount || 0, true)
                      }}</strong
                    >
                    </div>
                </div>
              </li>
            </ul>
            <ul class="list-group rounded shadow-sm" v-if="top_resellers_balance.length==0 && !loading">
              <li class="list-group-item py-3">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-primary font-size-20"><i class="uil uil-user"></i></span></div>
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                        No Data Available
                     </span
                    >
                      <strong class="fw-medium text-dark font-size-16 d-block"
                      > </strong
                    >
                    </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-4">
          <div class="mb-3">
            <div class="media align-items-center d-flex mb-2">
              <div class="avatar-xs me-2">
                <span class="avatar-title bg-info rounded-circle text-white"><i class="uil uil-wallet"></i></span></div>
                <div class="media-body"><h5 class="font-size-16 mb-0 text-dark"><small class="font-size-16 d-block">Top 10</small>Balance Merchants</h5>
                </div>
             </div>
            <ul class="list-group rounded shadow-sm" v-if="loading && !liveUpdateLoading">
              <li class="list-group-item pb-2 placeholder-glow" v-for="(value,index) in [0,1,2]" :key="index">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-info font-size-20"><i class="uil uil-user"></i></span></div>
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                        <span class="placeholder col-8 col-lg-4 rounded"></span></span
                    >
                      <strong class="fw-medium font-size-16 d-block"
                      >
                      <span class="placeholder rounded col-lg-6"></span>
                      </strong
                    >
                    </div>
                </div>
              </li>
             
            </ul>
            <ul class="list-group rounded shadow-sm">
              <li class="list-group-item pb-2 " v-for="(value,index) in top_merchants_balance" :key="index">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-info font-size-20"><i class="uil uil-user"></i></span></div>
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                     {{value.merchant_name}}</span
                    >
                      <strong class="fw-medium text-dark font-size-16 d-block"
                      >{{ currencySymbol }}
                      {{
                        convertCurrencyFormat(value.amount || 0, true)
                      }}</strong
                    >
                    </div>
                </div>
              </li>
            </ul>
            <ul class="list-group rounded shadow-sm" v-if="top_merchants_balance.length==0 && !loading">
              <li class="list-group-item py-3">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-info font-size-20"><i class="uil uil-user"></i></span></div>
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                        No Data Available
                     </span
                    >
                      <strong class="fw-medium text-dark font-size-16 d-block"
                      > </strong
                    >
                    </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-4">
          <div class="mb-3">
            <div class="media align-items-center d-flex mb-2">
              <div class="avatar-xs me-2">
                <span class="avatar-title bg-warning rounded-circle text-white"><i class="uil uil-clock"></i></span></div>
                <div class="media-body"><h5 class="font-size-16 mb-0 text-dark"><small class="font-size-16 d-block">Last 10 Trasanctions</small> Processing Time</h5>
                </div>
             </div>
            <ul class="list-group rounded shadow-sm" v-if="loading2 && !liveUpdateLoading">
              <li class="list-group-item pb-2 placeholder-glow" v-for="(value,index) in [0,1,2]" :key="index">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-warning font-size-22"><i class="uil uil-arrow-circle-down"></i></span></div>
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                        <span class="placeholder col-8 col-lg-4 rounded"></span></span
                    >
                      <strong class="fw-medium font-size-16 d-block"
                      >
                      <span class="placeholder rounded col-lg-6"></span>
                      </strong
                    >
                    </div>
                </div>
              </li>
              </ul>
              <ul class="list-group rounded shadow-sm" v-else>
              <li class="list-group-item pb-2" v-for="(value,index) in admin_overview.last_10_transaction_processing_time" :key="index">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-warning font-size-22"><i class="uil uil-arrow-circle-down"></i></span></div>
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                     {{value.deposit_id}}</span
                    >
                      <strong class="fw-medium text-dark font-size-16 d-block"
                      > {{value.proccessing_time}}</strong
                    >
                    </div>
                </div>
              </li>
            </ul>
            <ul class="list-group rounded shadow-sm" v-if="admin_overview.last_10_transaction_processing_time.length==0 && !loading">
              <li class="list-group-item py-3">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-warning font-size-22"><i class="uil uil-arrow-circle-down"></i></span></div>
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                        No Data Available
                     </span
                    >
                      <strong class="fw-medium text-dark font-size-16 d-block"
                      > </strong
                    >
                    </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row mb-4" v-else-if="account_type=='reseller'">

        <div class="col-12 col-lg-6 col-xl-6">
          <div class="mb-3">
            <div class="media align-items-center d-flex mb-2">
              <div class="avatar-xs me-2">
                <span class="avatar-title bg-info rounded-circle text-white"><i class="uil uil-wallet"></i></span></div>
                <div class="media-body"><h5 class="font-size-16 mb-0 text-dark"><small class="font-size-16 d-block">Top 10</small>Balance Resellers</h5>
                </div>
             </div>
            <ul class="list-group rounded shadow-sm">
              <li class="list-group-item pb-2 placeholder-glow" v-if="loading && !liveUpdateLoading">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-info font-size-20"><i class="uil uil-user"></i></span></div>
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                        <span class="placeholder col-8 col-lg-4 rounded"></span></span
                    >
                      <strong class="fw-medium font-size-16 d-block"
                      >
                      <span class="placeholder rounded col-lg-6"></span>
                      </strong
                    >
                    </div>
                </div>
              </li>
              <li class="list-group-item pb-2 placeholder-glow" v-if="loading">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-info font-size-20"><i class="uil uil-user"></i></span></div>
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                        <span class="placeholder col-8 col-lg-4 rounded"></span></span
                    >
                      <strong class="fw-medium font-size-16 d-block"
                      >
                      <span class="placeholder rounded col-lg-6"></span>
                      </strong
                    >
                    </div>
                </div>
              </li>
              <li class="list-group-item pb-2 " v-for="(value,index) in top_resellers_balance" :key="index">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-info font-size-20"><i class="uil uil-user"></i></span></div>
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                     {{value.reseller_name}}</span
                    >
                      <strong class="fw-medium text-dark font-size-16 d-block"
                      >{{ currencySymbol }}
                      {{
                        convertCurrencyFormat(value.amount || 0, true)
                      }}</strong
                    >
                    </div>
                </div>
              </li>
            </ul>
            <ul class="list-group rounded shadow-sm" v-if="top_resellers_balance.length==0 && !loading">
              <li class="list-group-item py-3">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-primary font-size-20"><i class="uil uil-user"></i></span></div>
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                        No Data Available
                     </span
                    >
                      <strong class="fw-medium text-dark font-size-16 d-block"
                      > </strong
                    >
                    </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-6">
          <div class="mb-3">
            <div class="media align-items-center d-flex mb-2">
              <div class="avatar-xs me-2">
                <span class="avatar-title bg-primary rounded-circle text-white"><i class="uil uil-wallet"></i></span></div>
                <div class="media-body"><h5 class="font-size-16 mb-0 text-dark"><small class="font-size-16 d-block">Top 10</small>Balance Merchants</h5>
                </div>
             </div>
            <ul class="list-group rounded shadow-sm">
              <li class="list-group-item pb-2 placeholder-glow" v-if="loading">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-primary font-size-20"><i class="uil uil-user"></i></span></div>
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                        <span class="placeholder col-8 col-lg-4 rounded"></span></span
                    >
                      <strong class="fw-medium font-size-16 d-block"
                      >
                      <span class="placeholder rounded col-lg-6"></span>
                      </strong
                    >
                    </div>
                </div>
              </li>
              <li class="list-group-item pb-2 placeholder-glow" v-if="loading">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-primary font-size-20"><i class="uil uil-user"></i></span></div>
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                        <span class="placeholder col-8 col-lg-4 rounded"></span></span
                    >
                      <strong class="fw-medium font-size-16 d-block"
                      >
                      <span class="placeholder rounded col-lg-6"></span>
                      </strong
                    >
                    </div>
                </div>
              </li>
              <li class="list-group-item pb-2 " v-for="(value,index) in top_merchants_balance" :key="index">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-primary font-size-20"><i class="uil uil-user"></i></span></div>
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                     {{value.merchant_name}}</span
                    >
                      <strong class="fw-medium text-dark font-size-16 d-block"
                      >{{ currencySymbol }}
                      {{
                        convertCurrencyFormat(value.amount || 0, true)
                      }}</strong
                    >
                    </div>
                </div>
              </li>
            </ul>
            <ul class="list-group rounded shadow-sm" v-if="top_merchants_balance.length==0 && !loading">
              <li class="list-group-item py-3">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-info font-size-20"><i class="uil uil-user"></i></span></div>
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                        No Data Available
                     </span
                    >
                      <strong class="fw-medium text-dark font-size-16 d-block"
                      > </strong
                    >
                    </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Lottie
      :path="'animate/loading-168.json'"
      :title="'Loading'"
      :info="'Please wait...'"
      :show="showLottie"
      ref="lottieFunc"
    />
    <Common ref="commonFunc" />
  </Layout>
</template>
<script>
//import countTo from "vue-count-to";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
export const equal2A = (value) => {
  return value !== "YOUR_API_ID";
};
export const equal2B = (value) => {
  return value !== "YOUR_API_HASH";
};

/**
 * Settings page
 */
const PageTitle = "Dashboard";
const CountryDefault = { name: "All Countries", value: "-", status: "" };
export default {
  components: {
    Layout,
    PageHeader,
    Lottie,
    Common,
    //countTo
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    };
  },
  data() {
    return {
      accessToken: "",
      accessEmail: "",
      accessPhone: "",
      accessUsername: "",
      showLottie: false,
      account_type: "",
      title: "",
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: "",
          active: true,
        },
      ],
      formData: {
        apiID: "YOUR_API_ID",
        apiHash: "YOUR_API_HASH",
      },
      submitted: false,
      loading: true,
      loading2: true,
      loading3: true,
      liveUpdateIntervalId: null,
      liveUpdate:false,
      liveUpdateLoading:false,
      showDemoData:false,
      showDemoDataLoading:false,
      buttonLoading: [false, false, false],
      currencySymbol: "",
      selectedCountry: CountryDefault,
      countryList: [CountryDefault],
      selectedPg: {},
      pgList: [],
      inactivePgList: [],
      contractDetails: [],
      transactionFee: "-",
      selectedStatisticsType: "1M",
      StatisticsTotal: [
        {
          label: "Total Payin",
          amount: "-",
        },
        {
          label: "Total Payout",
          amount: "-",
        }
      ],
      topStatistics: [
      {
        label: "Payin Today",
        label2: "Total Payin This Month",
        data: "",
        data2: "",
        data3: "",
        data4: "",
        data5:  ""
      },
      {
        label: "Payout Today",
        label2: "Total Payout This Month",
        data: "",
        data2: "",
        data3: "",
        data4: "",
        data5:  ""
      },
      {
        label: "Settlement Today",
        label2: "Total Settlement This Month",
        data: "",
        data2: "",
        data3: "",
        data4: "",
        data5:  ""
      },
      // {
      //   label: "Profit Today",
      //   label2: "Total Profit This Month",
      //   data: "",
      //   data2: "",
      //   data3: "",
      //   data4: "",
      //   data5:  ""
      // },
      ],
      pending_list: {
        payin:0,
        payout:0,
        settlement:0,
        withdrawal:0
      },
      admin_overview:{
        new_reseller_today:0,
        new_reseller_this_months:0,
        total_resellers:0,
        new_merchant_today:0,
        new_merchant_this_months:0,
        total_merchants:0,
        pending_transactions:0,
        longest_period_transaction:"",
        success_transactions:0,
        total_transactions:0,
        success_rate:"",
        top_pg_balance:[],
        last_10_transaction_processing_time:[],
        success_rate_per_currency:[],
      },
      top_merchants_balance:[],
      top_resellers_balance:[],
      showAlerts:false,
      depositData: {
        series: [
          {
            name: "Desktops",
            data: [
              /* your data values */
            ],
          },
        ],
        chartOptions: {
          chart: {
            type: "line", // Add the chart type property
          },
          // Rest of your chart options
        },
      },
      pgBalanceChart: {
          series: [
            {
              name: 'Deposit Amount',
              data: []
            }
          ],
          chartOptions: {
            chart: {
              height: 420,
              type: 'bar'
            },
            plotOptions: {
              bar: {
                columnWidth: '60%'
              }
            },
            colors: ['#00E396'],
            dataLabels: {
              enabled: false
            },
            legend: {
              show: true,
              showForSingleSeries: true,
              customLegendItems: ['Deposit Amount', 'Balance Limit'],
              markers: {
                fillColors: ['#00E396', '#775DD0']
              }
            }
          },
      },
      successRateChart: {
          series: [],
          chartOptions: {},
      },
      pendingTransactionChart: {
          series: [],
          chartOptions: {},
      },
      payinMerchants:[],
      payoutMerchants:[],
      currenciesData: []
    };
  },
  validations: {
    formData: {
      apiID: {
        required,
        equal2A,
      },
      apiHash: {
        required,
        equal2B,
      },
    },
  },
  middleware: "authentication",
  async mounted() {
    await this.reload();
    this.title = PageTitle;
    this.items[1].text = PageTitle;
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    this.accessToken = this.$refs.commonFunc.getToken();
    this.accessUsername = this.$refs.commonFunc.getUsername();
    this.getCountries();
    // if (this.account_type == "merchant") {
    //   //this.getMerchantData();
    //   this.getDashboard();
    // }
  },
  async created() {
    await this.reload();
    this.liveUpdate = true;
    this.liveUpdates()
  },
  beforeDestroy() {
    // Clear the interval when the component is about to be destroyed
    if (this.liveUpdateIntervalId) {
      clearInterval(this.liveUpdateIntervalId);
    }
  },
  methods: {
    getChartOptions(currency) {
      var self = this;
      return {
        colors : ['#4576b5', '#4d3a96'],
        chart: {
          id: `${currency.toLowerCase()}-chart`,
          height: "260px",
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '75%',
            borderRadiusApplication: 'end',
            borderRadiusWhenStacked: 'last',
            borderRadius: 3,
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Pay In', 'Pay Out', 'Settl.', 'Pay In', 'Pay Out', 'Settl.'],
          tickPlacement: 'between',
          labels: {
              show: true,
              rotate: 360,
              rotateAlways: false,
              hideOverlappingLabels: true,
              showDuplicates: false,
              trim: true,
              minHeight: undefined,
              maxHeight: 120,
              style: {
                  fontSize: '10px',
                  fontFamily: 'Roboto',
                  cssClass: 'apexcharts-xaxis-label'
              },
              offsetX: 0,
              offsetY: 0,
              format: undefined,
              formatter: undefined,
              datetimeUTC: false,
              datetimeFormatter: {
                  year: 'yyyy',
                  month: "MMM 'yy",
                  day: 'dd MMM',
                  hour: 'HH:mm',
              },
          },
          group: {
                style: {
                  fontSize: '12px',
                  fontWeight: 400,
                  fontFamily: "Roboto",
                },
                groups: [
                  { title: 'Today Vol.', cols: 3, },
                  { title: 'Yesterday Vol.', cols: 3 }
                ]
              }
        },
        yaxis: {
          forceNiceScale: true,
          min: 0,
          title: {
            text: 'Volume'
          },
          labels: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return `${self.convertCurrencyFormat(parseFloat(y),false)}`;
              }
              return y;
              },
          }
        },
        legend: {
          position: 'top'
        },
        tooltip: {
            shared: true,
            intersect: false,
              custom: function({series, seriesIndex, dataPointIndex}) {
              console.log(seriesIndex);
              var infoTooltip = "";
              var todayOrYesterday = "";
              if (parseInt(dataPointIndex)==0){
                infoTooltip = "Pay In Vol. (TD)"
                todayOrYesterday="Today"
              }
              else if (parseInt(dataPointIndex)==1){
                infoTooltip = "Pay Out Vol. (TD)"
                todayOrYesterday="Today"
              }
              else if (parseInt(dataPointIndex)==2){
                infoTooltip = "Settlement Vol. (TD)"
                todayOrYesterday="Today"
              }
              else if (parseInt(dataPointIndex)==3){
                infoTooltip = "Pay In Vol. (YD)"
                todayOrYesterday="Yesterday"
              }
              else if (parseInt(dataPointIndex)==4){
                infoTooltip = "Pay Out Vol. (YD)"
                todayOrYesterday="Yesterday"
              }
              else if (parseInt(dataPointIndex)==5){
                infoTooltip = "Settlement Vol. (YD)"
                todayOrYesterday="Yesterday"
              }
              return `
              <div class="p-2">
              <div class="font-size-12 fw-medium text-info mb-1">${infoTooltip}</div>
              <div class="font-size-12">${todayOrYesterday}: <strong>${self.convertCurrencyFormat(parseFloat(series[seriesIndex][dataPointIndex]))}</strong></div>
              <div class="font-size-12">This Month: <strong>${self.convertCurrencyFormat(parseFloat(series[1][dataPointIndex]))}</strong></div>
              </div>
              `}
        },
      };
    },
    getSeriesData(currencyData) {
      console.log(currencyData)
      return [
          {
            name: 'Volume',
            data: currencyData.data
          }, {
            name: 'Volume of Month',
            data: currencyData.month,
          }
        ]
    },
    liveUpdates() {
      this.liveUpdateIntervalId = setInterval(() => {
        if (this.liveUpdate) {
          console.log('Updating');
          this.updateCurrency();
          this.liveUpdateLoading = true;
        }
      }, 60000);
    },
    showDemoDataFunc() {
      this.$Progress.start();
      this.showDemoDataLoading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("onOffDemoData", this.showDemoData);
      axios({
        method: "post",
        url: appConfig.DemoAPI,
        //url: appConfig.APIHostAdmin + "controller/admin/getPgListByCurrency",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData)
            //this.updateCurrency();
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            this.showDemoDataLoading = false;
          }
          this.$Progress.finish();
        })
        .catch((error) => {
          this.showDemoDataLoading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    convertCurrencyFormat(value,show00) {
      if ( this.$refs.commonFunc){
        if (show00==true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        }else{
            // Assuming this.transactionLimit is defined in your component's data
            return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
        }
      }
    },
    convertThousandMillion(value) {
      // Assuming this.transactionLimit is defined in your component's data
      return this.$refs.commonFunc
        .convertThousandMillion(parseInt(value))
        .replaceAll(".00", "");
    },
    updateCurrency() {
      console.log("updateCurrency");
      this.currencySymbol = this.selectedCountry.value;
      this.getDashboard();
      this.getBalance();
      if (this.account_type == "admin") {
        this.getAdminOverview();
      }
    },
    async getCountries() {
      console.log("getCountries");
      this.$Progress.start();
      this.loading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + "controller/admin/getSupportedCurrency",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.countryList = [];
            resData.data.forEach((element) => {
              this.countryList.push(element);
            });
            if (this.countryList.length > 0) {
              this.selectedCountry = this.countryList[0];
            }
            this.updateCurrency();
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
            this.loading = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            this.loading = false;
          }
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    getPgList() {
      console.log("getPgList");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("currency", this.selectedCountry.value);
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + "controller/admin/getPgListByCurrency",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.selectedPg = {};
            this.pgList = [];
            resData.pg_list.forEach((element) => {
              this.pgList.push({
                id: element.id,
                payment_gateway_name: element.payment_gateway_name,
                payment_gateway_code: element.payment_gateway_code,
                currency: element.currency,
                wallet_info: element.wallet_info,
                status: element.status === "activated" ? 1 : 0,
              });
            });
            
         
            this.getDashboard();
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            this.loading = false;
            this.disabled = false;
          }
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    updateStatistics() {
      console.log("updateStatistics");
      this.getDashboard();
    },
    getBalance(){
        console.log("getBalance")
        this.$Progress.start();
        this.loading3 = true
        this.disabled = true
        this.contractDetails =[]
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        axios({
          method: "post",
          //url: appConfig.DemoAPI,
          url: appConfig.APIHostAdmin + "controller/admin/getReportBalanceForResellerMerchant",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              this.contractDetails = resData.balance;
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading3 = false;
            this.disabled = false
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading3 = false;
            this.disabled = false
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
    },
    async getDashboard() {
      console.log("getDashboard")
      this.$Progress.start();
      //this.loading = true;
      // try {
      //   const result = await this.test();
      //   console.log(result); // You can do something with the result if needed
      // } catch (error) {
      //   console.error("Error in test function:", error);
      // } finally {
      //   console.log("end");
      // }
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("currency", this.currencySymbol);
      bodyFormData.append("statisticsType", this.selectedStatisticsType);
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + "controller/admin/getReportDashboard",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData);

            const data = {
              statisticsTotalPayin: resData.statistics.total_payin || 0,
              statisticsTotalPayout: resData.statistics.total_payout || 0,
              top_balance_resellers: resData.top_balance_resellers,
              top_balance_merchants: resData.top_balance_merchants,
              payin_today: resData.payin_today,
              payin_month: resData.payin_month,
              payin_transaction_today: resData.payin_transaction_today,
              payin_transaction_month: resData.payin_transaction_month,
              payout_today: resData.payout_today,
              payout_month: resData.payout_month,
              payout_transaction_today: resData.payout_transaction_today,
              payout_transaction_month: resData.payout_transaction_month,
              settlement_today: resData.settlement_today,
              settlement_month: resData.settlement_month,
              settlement_transaction_today: resData.settlement_transaction_today,
              settlement_transaction_month: resData.settlement_transaction_month,
              settlement_outstanding: resData.settlement_outstanding,
              each_currency_balance_volume: [
                {
                  currency: "MYR",
                  payin_today:"2166",
                  payin_yesteday:"80",
                  payin_month_with_today:"3166",
                  payin_month_with_yesterday:"200",
                  payout_today:"55",
                  payout_yesteday:"45",
                  payout_month_with_today:"285",
                  payout_month_with_yesterday:"230",
                  settlement_today:"58",
                  settlement_yesteday:"100",
                  settlement_month_with_today:"258",
                  settlement_month_with_yesterday:"200",
                  balance: "1000000"
                },
                {
                  currency: "INR",
                  payin_today:"66",
                  payin_yesteday:"80",
                  payin_month_with_today:"266",
                  payin_month_with_yesterday:"200",
                  payout_today:"55",
                  payout_yesteday:"45",
                  payout_month_with_today:"285",
                  payout_month_with_yesterday:"230",
                  settlement_today:"58",
                  settlement_yesteday:"100",
                  settlement_month_with_today:"258",
                  settlement_month_with_yesterday:"200",
                  balance: "1000000"
                },{
                  currency: "INR_NATIVE",
                  payin_today:"66",
                  payin_yesteday:"80",
                  payin_month_with_today:"266",
                  payin_month_with_yesterday:"200",
                  payout_today:"55",
                  payout_yesteday:"45",
                  payout_month_with_today:"285",
                  payout_month_with_yesterday:"230",
                  settlement_today:"58",
                  settlement_yesteday:"100",
                  settlement_month_with_today:"258",
                  settlement_month_with_yesterday:"200",
                  balance: "1000000"
                },
                {
                  currency: "THB",
                  payin_today:"66",
                  payin_yesteday:"80",
                  payin_month_with_today:"266",
                  payin_month_with_yesterday:"200",
                  payout_today:"55",
                  payout_yesteday:"45",
                  payout_month_with_today:"285",
                  payout_month_with_yesterday:"230",
                  settlement_today:"58",
                  settlement_yesteday:"100",
                  settlement_month_with_today:"258",
                  settlement_month_with_yesterday:"200",
                  balance: "2000000"
                },
                {
                  currency: "VND",
                  payin_today:"66",
                  payin_yesteday:"80",
                  payin_month_with_today:"266",
                  payin_month_with_yesterday:"200",
                  payout_today:"55",
                  payout_yesteday:"45",
                  payout_month_with_today:"285",
                  payout_month_with_yesterday:"230",
                  settlement_today:"58",
                  settlement_yesteday:"100",
                  settlement_month_with_today:"258",
                  settlement_month_with_yesterday:"200",
                  balance: "2000000"
                },
                {
                  currency: "IDR_NATIVE",
                  payin_today:"66",
                  payin_yesteday:"80",
                  payin_month_with_today:"266",
                  payin_month_with_yesterday:"200",
                  payout_today:"55",
                  payout_yesteday:"45",
                  payout_month_with_today:"285",
                  payout_month_with_yesterday:"230",
                  settlement_today:"58",
                  settlement_yesteday:"100",
                  settlement_month_with_today:"258",
                  settlement_month_with_yesterday:"200",
                  balance: "1000000"
                },
                {
                  currency: "BRL",
                  payin_today:"66",
                  payin_yesteday:"80",
                  payin_month_with_today:"266",
                  payin_month_with_yesterday:"200",
                  payout_today:"55",
                  payout_yesteday:"45",
                  payout_month_with_today:"285",
                  payout_month_with_yesterday:"230",
                  settlement_today:"58",
                  settlement_yesteday:"100",
                  settlement_month_with_today:"258",
                  settlement_month_with_yesterday:"200",
                  balance: "1000000"
                }
              ]
              // profit_today: resData.profit_today,
              // profit_month: resData.profit_month,
              // profit_transaction_today: resData.profit_transaction_today,
              // profit_transaction_month: resData.profit_transaction_month,
             
            };
            this.top_merchants_balance = data.top_balance_merchants;
            this.top_resellers_balance = data.top_balance_resellers;

            this.topStatistics = [
            {
              label: "Payin Today",
              label2: "Payin This Month",
              data: data.payin_today,
              data2: data.payin_month,
              data3: data.payin_transaction_today,
              data4: data.payin_transaction_month,
              data5:  ""
            },
            {
              label: "Payout Today",
              label2: "Payout This Month",
              data: data.payout_today,
              data2: data.payout_month,
              data3: data.payout_transaction_today,
              data4: data.payout_transaction_month,
              data5:  ""
            },
            {
              label: "Settlement Today",
              label2: "Settlement This Month",
              data: data.settlement_today,
              data2: data.settlement_month,
              data3: data.settlement_transaction_today,
              data4: data.settlement_transaction_month,
              data5: data.settlement_outstanding,
            },
            ];
            
            this.currenciesData=[]
            data.each_currency_balance_volume.forEach(element => {
              this.currenciesData.push({
                currency: element.currency,
                data:[
                  element.payin_today, 
                  element.payout_today, 
                  element.settlement_today,
                  element.payin_yesteday, 
                  element.payout_yesteday, 
                  element.settlement_yesteday
                ],
                month:[
                  element.payin_month_with_today, 
                  element.payout_month_with_today, 
                  element.settlement_month_with_today, 
                  element.payin_month_with_yesterday, 
                  element.payout_month_with_yesterday, 
                  element.settlement_month_with_yesterday
                ],
                balance: element.balance
              })
            });
            this.StatisticsTotal= [
            {
              label: "Total Payin",
              amount: parseFloat(data.statisticsTotalPayin),
            },
            {
              label: "Total Payout",
              amount: parseFloat(data.statisticsTotalPayout),
            }]
            var self = this;
            this.depositData = {
              series: [
                {
                  name: "Total Payin",
                  data: resData.statistics.payin,
                },
                {
                  name: "Total Payout",
                  data: resData.statistics.payouts,
                },
              ],
              chartOptions: {
                chart: {
                  height: 420,
                  type: "line",
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                },
                colors: ["#9e2988", "#462f94"],
                stroke: {
                  width: [3, 3],
                  curve: "smooth",
                },
                title: {
                  text: "Statistical Overview",
                  align: "left",
                },
                
                tooltip: {
                  shared: true,
                  intersect: false,
                  y: {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return `${self.currencySymbol} ${self.convertCurrencyFormat(parseFloat(y),false)}`;
                      }
                      return y;
                    },
                  },
                },
                grid: {
                  borderColor: "#f1f1f1",
                },
                labels: resData.statistics.date, //dateArray,
                markers: {
                  size: 3,
                  colors: "#ffffff",
                  strokeColors: ["#9e2988", "#462f94"],
                  strokeWidth: 1,
                  hover: {
                    size: undefined,
                    sizeOffset: 2,
                  },
                },
                yaxis: {
                  title: {
                    text: "Amount",
                  },
                  min: 0,
                  labels: {
                      formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return `${self.convertCurrencyFormat(parseFloat(y),true)}`;
                        }
                        return y;
                        },
                    },
                },
                legend: {
                  position: "top",
                  horizontalAlign: "right",
                  floating: true,
                  offsetY: -25,
                  offsetX: -5,
                },
              },
            };
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    async test() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 5000);
      });
    },
    async getAdminOverview() {
      console.log("getAdminOverview")
      this.$Progress.start();
      //this.loading2 = true;
      
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("currency", this.currencySymbol);
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + "controller/admin/getAdminDashboard",//"controller/admin/getPgListByCurrency",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData);
            
            const demoData = {
              new_reseller_today:10,
              new_reseller_this_months:60,
              total_resellers:100,
              new_merchant_today:20,
              new_merchant_this_months:100,
              total_merchants:1000,
              pending_transactions:80,
              longest_period_transaction:"4:30",
              success_transactions:826000,
              total_transactions:1180000,
              success_rate:"70",
              top_pg_balance:[],
              pending_list: {
                payin:0,
                payout:2,
                settlement:4,
                withdrawal:1
              },
              last_10_transaction_processing_time:[
                {
                  "deposit_id":"PI2CGDNSX2",
                  "proccessing_time": "0:11"
                },
                {
                  "deposit_id":"PIQ3GDNSX2",
                  "proccessing_time": "0:09"
                },
                {
                  "deposit_id":"PI1CGDNSX2",
                  "proccessing_time": "0:08"
                },
                {
                  "deposit_id":"PIQ3GDNSX2",
                  "proccessing_time": "0:11"
                },
                {
                  "deposit_id":"PIQ1GDNSX2",
                  "proccessing_time": "0:15"
                },
                {
                  "deposit_id":"PIQ2GDNSX2",
                  "proccessing_time": "0:21"
                },
                {
                  "deposit_id":"PIQ2GDNSX2",
                  "proccessing_time": "0:10"
                },
                {
                  "deposit_id":"PIQCGDNSX1",
                  "proccessing_time": "0:09"
                },
                {
                  "deposit_id":"PIQCGDNSX2",
                  "proccessing_time": "0:08"
                },
                {
                  "deposit_id":"PIQCGN7SE5",
                  "proccessing_time": "0:08"
                },
              ],
              "inactive_pg_list":[
                {
                    "id": "4",
                    "payment_db_code": "1693894387eee9dc4a9e0da7c8",
                    "payment_gateway_name": "SWIFTPAY",
                    "payment_gateway_code": "swiftpay",
                    "status": "activated",
                    "currency": "MYR",
                    "country": "MALAYSIA",
                },
                {
                    "id": "1",
                    "payment_db_code": "1684470785B192EBC3",
                    "payment_gateway_name": "AMPAY",
                    "payment_gateway_code": "ampay",
                    "status": "activated",
                    "currency": "MYR",
                    "country": "MALAYSIA",
                },
                {
                  "id": "2",
                  "payment_db_code": "1693301573afc3abf996a91d62",
                  "payment_gateway_name": "LUCKY PAY",
                  "payment_gateway_code": "luckypay",
                  "status": "activated",
                  "currency": "INR",
                  "country": "INDIA",
              },
              ],
              "success_rate_per_currency":[
                {
                  "percentage":"100",
                  "currency":"BRL",
                  "total_success_transactions":"2",
                  "total_transactions":"2",
                },
                {
                  "percentage":"56",
                  "currency":"INR",
                  "total_success_transactions":"14",
                  "total_transactions":"25",
                },
                {
                  "percentage":"89",
                  "currency":"INR_NATIVE",
                  "total_success_transactions":"16",
                  "total_transactions":"18",
                },
                {
                  "percentage":"100",
                  "currency":"IDR_NATIVE",
                  "total_success_transactions":"1156",
                  "total_transactions":"1156",
                },
                {
                  "percentage":"80",
                  "currency":"MYR",
                  "total_success_transactions":"12",
                  "total_transactions":"15",
                },
                {
                  "percentage":"100",
                  "currency":"THB",
                  "total_success_transactions":"15",
                  "total_transactions":"15",
                },
                {
                  "percentage":"50",
                  "currency":"VND",
                  "total_success_transactions":"10",
                  "total_transactions":"20",
                }
                
              ]
            }
            console.log(demoData)
            this.admin_overview={
              new_reseller_today: resData.new_reseller_today,
              new_reseller_this_months: resData.new_reseller_this_months,
              total_resellers:  resData.total_resellers,
              new_merchant_today: resData.new_merchant_today,
              new_merchant_this_months: resData.new_merchant_this_months,
              total_merchants: resData.total_merchants,
              pending_transactions: resData.pending_transactions,
              longest_period_transaction: resData.longest_period_transaction,
              success_transactions: resData.success_transactions,
              total_transactions: resData.total_transactions,
              success_rate: resData.success_rate,
              top_pg_balance: resData.top_pg_balance,
              last_10_transaction_processing_time:resData.last_10_transaction_processing_time,
              success_rate_per_currency: demoData.success_rate_per_currency
            }
            this.showDemoData = resData.is_included_demo_data || false

            var self= this;
            this.pgList = [];
            var forChart = [];
            var forChartPoints = [];

            resData.top_pg_balance.forEach((element, index) => {
              console.log(element)
              this.admin_overview.top_pg_balance.push({
                id: element.id,
                payment_gateway_name: element.payment_gateway_name,
                payment_gateway_code: element.payment_gateway_code,
                currency: element.currency,
                wallet_info: element.wallet_info,
                status: element.status === "activated" ? 1 : 0,
              });

              let successRate = parseFloat(element.success_rate);
              // Check if the decimal part is not .00
              if (successRate % 1 !== 0) {
                successRate = successRate.toFixed(2);
              }

              forChart.push({
                x: element.payment_gateway_name,
                y: parseFloat(element.wallet_info.pg_deposit_balance),
                limit: parseFloat(element.rate_info.balance_limit),
                success_rate: `${successRate}%`,
                goals: [
                  {
                    name: 'Limit',
                    value: parseFloat(element.rate_info.balance_limit),
                    strokeHeight: 3,
                    strokeColor: '#9e2988',
                  }
                ]
              })
             
              forChartPoints.push({
                x: element.payment_gateway_name,
                y: element.rate_info.balance_limit,
                seriesIndex: index,
                marker: {
                  size: 4,
                  fillColor: '#fff',
                  height: 10 // You can set the height of the point marker here
                },
                label: {
                  borderWidth: 0,
                  borderColor: '#9e2988',
                  offsetY: -2,
                  style: {
                    color: '#fff',
                    fontSize: '11px',
                    fontWeight: 400,
                    fontFamily: "Roboto",
                    background: '#9e2988',
                  },
                  text: `Limit: ${self.convertCurrencyFormat(parseFloat(element.rate_info.balance_limit))}`,
                }
              })
            });
            //https://apexcharts.com/docs/options/annotations/
            //set other fake data
            const fakeLength = 5
            if (forChart.length<fakeLength){
              for (let i = forChart.length + 1; i < fakeLength; i++) {
                forChart.push({
                  x: "",
                  y: "0",
                  limit: "0",
                  success_rate: "",
                  goals: []
                })
              }
            }

            this.inactivePgList = [];
            //only return the total_deposit>balance && inactive
            this.inactivePgList = resData.inactive_pg_list
            if (this.inactivePgList.length){
              this.showAlerts = true
            }
            this.pending_list ={
                payin: resData.pending_list.payin,
                payout: resData.pending_list.payout,
                settlement: resData.pending_list.settlement,
                withdrawal: resData.pending_list.withdrawal
            }
            //pg balance chart
            this.pgBalanceChart={
            series: [
              {
                name: 'Deposit Balance',
                data: forChart
              }
            ],
            chartOptions: {
              chart: {
                height: 450,
                type: "bar",
                stacked: true,
                toolbar: {
                  show: false,
                },
                zoom: {
                  enabled: false,
                },
              },
              plotOptions: {
                bar: {
                  columnWidth: '60%',
                  barHeight: '100%',
                  borderRadius: 3,
                  dataLabels: {
                    position: 'top', // top, center, bottom
                    hideOverflowingLabels:false,
                  },
                  //https://apexcharts.com/docs/options/plotoptions/bar/
                },
              },
              annotations: {
                points: forChartPoints
              },
              colors: ['#392e4a'],
              dataLabels: {
                enabled: true,
                formatter: function (val, { dataPointIndex }) {
                  var label = ""
                  if (forChart[dataPointIndex].x !==''){
                    // label = `${self.convertCurrencyFormat(parseFloat(forChart[dataPointIndex].y))} (${forChart[dataPointIndex].success_rate})`
                    label = `${forChart[dataPointIndex].success_rate}`
                  }
                  return label;
                },
                offsetY: -20,
                style: {
                  fontSize: '12px',
                  fontWeight: 'bold',
                  colors: ["#27ae60"]
                },
                minHeight: '0px',
              },
              fill: {
                opacity: 0.9,
              },
              xaxis: {
                min:0,
                forceNiceScale:false,
                labels: {
                    formatter: function (x) {
                      if (typeof x !== "undefined") {
                        return `${x}`;
                      }
                      return x;
                      },
                  },
                  //https://apexcharts.com/docs/options/xaxis/
              },
              yaxis: {
                min:0,
                forceNiceScale:false,
                labels: {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return `${self.convertCurrencyFormat(parseFloat(y))}`;
                      }
                      return y;
                      },
                  },
                  //https://apexcharts.com/docs/options/yaxis/
              },
              legend: {
                y: {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return `${self.convertCurrencyFormat(parseFloat(y))}`;
                      }
                      return y;
                    },
                  },
                show: true,
                showForSingleSeries: true,
                customLegendItems: ['Deposit Balance', 'Balance Limit', 'Success Rates' ],
                markers: {
                  fillColors: ['#392e4a', '#9e2988', '#27ae60']
                }
              },
              tooltip: {
                  shared: true,
                  intersect: false,
                   custom: function({series, seriesIndex, dataPointIndex}) {
                   return `<div class="p-2 font-size-12"><strong>${forChart[dataPointIndex].x}</strong><div>Deposit Balance: <strong>${self.convertCurrencyFormat(parseFloat(series[seriesIndex][dataPointIndex]))}</strong></div><div>Balance Limit: <strong>${self.convertCurrencyFormat(parseFloat(forChart[dataPointIndex].limit))}</strong></div>
                   <div>Success Rates: <strong>${forChart[dataPointIndex].success_rate}</strong></div>
                   `}
                  // y: {
                  //   formatter: function (y,  { seriesIndex, dataPointIndex }) {
                  //     if (typeof y !== "undefined") {
                  //       return `${self.convertCurrencyFormat(parseFloat(y))}<br> ${seriesIndex}<br>${dataPointIndex}`;
                  //     }
                  //     return y;
                  //   },
                  // },
              },
            },
            }
            ////////////////////////////////
            //success rate chart
            this.admin_overview.success_rate_per_currency = [];
            var chartSuccess = [];
            var chartSuccessPoints = [];
            var chartSuccessCurrency = []
            var chartSuccessTrx = [];
            var chartTotalTrx = []

            demoData.success_rate_per_currency.forEach((element, index) => {
              var successRate = parseFloat(element.percentage);
              // Check if the decimal part is not .00
              if (successRate % 1 !== 0) {
                successRate = successRate.toFixed(2);
              }

              var remainStack = element.total_transactions - element.total_success_transactions
              chartSuccessCurrency.push(element.currency)
              chartSuccessTrx.push(remainStack)
              chartTotalTrx.push(element.total_transactions)
              
              chartSuccess.push({
                x: element.currency,
                y: parseFloat(element.total_transactions),
                success: parseFloat(element.total_success_transactions),
                success_rate: `${successRate}%`,
                
              })
             
              chartSuccessPoints.push({
                x: element.currency,
                y: element.total_transactions,
                seriesIndex: index,
                marker: {
                  size: 0,
                  strokeColor: '#b4f0b4',
                  fillColor: '#fff',
                  height: 0 // You can set the height of the point marker here
                }
              })
            });

            //success chart
            this.successRateChart={
              series: [
              {
                name: 'Total Trx',
                data: chartTotalTrx
              },
              {
                name: 'Total Success Trx',
                data: chartSuccessTrx
              },
              // {
              //   name: 'Sucess Rate',
              //   data: chartSuccess
              // }
            ],
            chartOptions: {
              chart: {
                height: 450,
                type: "bar",
                stacked: true,
                toolbar: {
                  show: false,
                },
                zoom: {
                  enabled: false,
                },
              },
              plotOptions: {
                bar: {
                  borderRadiusApplication: 'end',
                  borderRadiusWhenStacked: 'last',
                  columnWidth: '60%',
                  barHeight: '100%',
                  borderRadius: 6,
                  dataLabels: {
                    position: 'top', // top, center, bottom
                    hideOverflowingLabels:false,
                  },
                  //https://apexcharts.com/docs/options/plotoptions/bar/
                },
              },
              annotations: {
                points: chartSuccessPoints
              },
              colors: ['#b4f0b4', '#47954a'],
              dataLabels: {
                enabled: false,
              },
              fill: {
                opacity: 1,
              },
              xaxis: {
                min:0,
                forceNiceScale: true,
                categories: chartSuccessCurrency
                  //https://apexcharts.com/docs/options/xaxis/
              },
              yaxis: {
                min:0,
                title: {
                  text: "Total Trx This Month",
                },
                forceNiceScale:true,
                labels: {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return `${self.convertCurrencyFormat(parseFloat(y))}`;
                      }
                      return y;
                      },
                  },
                  //https://apexcharts.com/docs/options/yaxis/
              },
              legend: {
                y: {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return `${self.convertCurrencyFormat(parseFloat(y))}`;
                      }
                      return y;
                    },
                  },
                show: true,
                showForSingleSeries: true,
                customLegendItems: ['Total Trx This Month', 'Successful Trx This Month' ],
                markers: {
                  fillColors: ['#47954a', '#b4f0b4', '#27ae60']
                }
              },
              tooltip: {
                  shared: true,
                  intersect: false,
                   custom: function({series, seriesIndex, dataPointIndex}) {
                    console.log(series, seriesIndex)
                   return `
                   <div class="p-2 font-size-12"><strong>${chartSuccess[dataPointIndex].x}</strong>
                   <div>Total Trx This Month: <strong>${self.convertCurrencyFormat(parseFloat(chartSuccess[dataPointIndex].y))}</strong></div>
                   <div>Successful Trx This Month: <strong>${self.convertCurrencyFormat(parseFloat(chartSuccess[dataPointIndex].success))}</strong></div>
                   <div>Success Rate: <strong>${chartSuccess[dataPointIndex].success_rate}</strong></div>
                   `}
              },
            },
            }

            //pending transaction chart
            var pendingCategory = [ 'Payin', 'Payout', 'Settlement', 'Withdrawal' ]
            var pendingTrxList = [
              resData.pending_list.payin,
              resData.pending_list.payout,
              resData.pending_list.settlement,
              resData.pending_list.withdrawal
            ]
            this.pendingTransactionChart = {
              series: [{
                data: pendingTrxList
              }],
              chartOptions: {
                chart: {
                  height: 450,
                  type: 'bar',
                  stacked: true,
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                  events: {
                    click: (chart, w, e) => {
                      console.log(e.dataPointIndex);

                      if (e.dataPointIndex === 0) {
                        this.$router.push({ name: 'processing payin' });
                      }
                      if (e.dataPointIndex === 1) {
                        this.$router.push({ name: 'pending payouts' });
                      }
                      if (e.dataPointIndex === 2) {
                        this.$router.push({ name: 'pending settlements' });
                      }
                      if (e.dataPointIndex === 3) {
                        this.$router.push({ name: 'pending withdrawal' });
                      }
                    }
                  }
                },
                colors: ['#FFA500', '#E56717', '#E18B6B', '#C36241'],
                plotOptions: {
                  bar: {
                    borderRadiusApplication: 'end',
                    borderRadiusWhenStacked: 'last',
                    columnWidth: '60%',
                    barHeight: '100%',
                    borderRadius: 6,
                    distributed: true,
                  },
                },
                dataLabels: {
                  enabled: false
                },
                legend: {
                  show: false
                },
                xaxis: {
                  categories: pendingCategory,
                  labels: {
                    style: {
                      colors: ['#FFA500', '#E56717', '#E18B6B', '#C36241'],
                    }
                  }
                },
                yaxis: {
                  min:0,
                  title: {
                    text: "Pending Transactions",
                  },
                  forceNiceScale:true,
                  labels: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                          return `${self.convertCurrencyFormat(parseFloat(y))}`;
                        }
                        return y;
                        },
                    }
                },
                tooltip: {
                  shared: true,
                  intersect: false,
                   custom: function({series, seriesIndex, dataPointIndex}) {
                    console.log(series[0][dataPointIndex], seriesIndex)
                   return `
                    <div class="p-2 font-size-12"><strong>${pendingCategory[dataPointIndex]}</strong>
                    <div>Pending:<strong> ${self.convertCurrencyFormat(series[0][dataPointIndex], false)}</strong></div>
                    </div>
                   `}
                  },
              }
            }


          }

          else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
         
          this.loading2 = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading2 = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    convertDate(dateStr) {
      try {
        const parts = dateStr.split(" ");
        const day = parts[0];
        const monthNumber = parseInt(parts[1]);

        if (isNaN(monthNumber) || monthNumber < 1 || monthNumber > 12) {
          throw new Error("Invalid month");
        }

        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const monthAbbreviation = months[monthNumber - 1];

        return day + " " + monthAbbreviation;
      } catch (error) {
        return "Invalid date format";
      }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          console.log(data);
          this.account_type = data.account_type;
          // if (data.otp_enabled==false) {
          //   setTimeout(() => {
          //     Swal.fire({
          //       imageUrl: require('@/assets/images/otp.png'),
          //       imageWidth: 320,
          //       imageHeight: 180,
          //       title: 'Two-Factor Authentication',
          //       html: `Increase your account's security by enabling two-factor authentication (2FA).`,
          //       confirmButtonColor: '#FA6541',
          //       showCancelButton: true,
          //       confirmButtonText: "Enable"
          //     }).then((value) => {
          //       console.log(value)
          //       if (value.isConfirmed){
          //         this.$router.push({name:"profile"})
          //       }
          //       // this.$refs['modal-addmerchant'].hide()
          //       // this.getData();
          //     })
          //   }, 10);
          // }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  },
};
</script>
<style scoped>
.avatar-title i{font-size: 18px;}
</style>
<style>
.custom-alert .close{color:#ff5f00 !important}
.apexcharts-yaxis-title-text{
  font-weight: normal !important;
}
</style>